/**
 *  AuthContext provides a structure for accessing and updating authentication state.
 * AuthProvider initializes this state based on the presence of an "id" in secureLocalStorage
 * and listens to local storage changes to maintain synchronization.
 * The useAuth hook allows easy access to this context throughout the application.
 */
import React, { createContext, useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

const AuthContext = createContext({
  isAuthenticated: false,
  setAuthenticated: (auth: boolean) => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(
    secureLocalStorage.getItem("id") !== null
  );

  useEffect(() => {
    const checkAuth = () => {
      setAuthenticated(secureLocalStorage.getItem("id") !== null);
    };

    // Listen for changes to local storage
    window.addEventListener("storage", checkAuth);

    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
