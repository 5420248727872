import React from "react";
import { useRoutes } from "react-router-dom";
// import routes from "common/router/routes";
import AppRoutes from "common/router/routes";
import { AuthProvider } from "common/router/AuthContext";

function App() {
  // const element = useRoutes(routes);

  return (
    <AuthProvider>
      <div className="App">
        <AppRoutes />
      </div>
    </AuthProvider>
  );
}

export default App;
