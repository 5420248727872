import SuspenseLazy from "common/components/SuspenseLazy";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "./AuthContext";

const Landing = SuspenseLazy(
  () =>
    import(/* webpackChunkName:"landingPage" */ "modules/landing/LandingPage")
);

const Login = SuspenseLazy(
  () => import(/* webpackChunkName:"landingPage" */ "modules/login/LoginPage")
);

const Signup = SuspenseLazy(
  () => import(/* webpackChunkName:"landingPage" */ "modules/login/SignupPage")
);

const Payment = SuspenseLazy(
  () => import(/* webpackChunkName:"landingPage" */ "modules/payment/Payment")
);

const FindPW = SuspenseLazy(
  () => import(/* webpackChunkName:"landingPage" */ "modules/login/FindPage")
);

const Gallery = SuspenseLazy(
  () =>
    import(/* webpackChunkName:"landingPage" */ "modules/login/myPage/Gallery")
);

const Create3D = SuspenseLazy(
  () =>
    import(
      /* webpackChunkName:"Create3DPage" */ "modules/create3d/Create3DPage"
    )
);

const Profile = SuspenseLazy(
  () =>
    import(/* webpackChunkName:"landingPage" */ "modules/login/myPage/Profile")
);

const PhotoBooth = SuspenseLazy(
  () =>
    import(/* webpackChunkName:"photoBooth" */ "modules/photoBooth/PhotoBooth")
);

const ToMesh = SuspenseLazy(
  () => import(/* webpackChunkName:"toMesh" */ "modules/toMesh/ToMesh")
);
const MeshUpload = SuspenseLazy(
  () => import(/* webpackChunkName:"MeshUpload" */ "modules/toMesh/MeshUpload")
);

const TMViewer = SuspenseLazy(
  () =>
    import(
      /* webpackChunkName:"TMViewer" */ "modules/toMesh/components/TMViewer"
    )
);

const ToMingle = SuspenseLazy(
  () => import(/* webpackChunkName:"ToMingle" */ "modules/toMingle/ToMingle")
);

const MingleUpload = SuspenseLazy(
  () =>
    import(/* webpackChunkName:"MeshUpload" */ "modules/toMingle/MingleUpload")
);

const TMGViewer = SuspenseLazy(
  () =>
    import(
      /* webpackChunkName:"TMViewer" */ "modules/toMingle/components/TMViewer"
    )
);

// const ResultViewer = SuspenseLazy(
//   () => import(/* webpackChunkName:"TMViewer" */ "modules/resultViewer/RMain")
// );

const ToAvatar = SuspenseLazy(
  () => import(/* webpackChunkName:"ToMingle" */ "modules/toAvatar/ToAvatar")
);
const AvatarUpload = SuspenseLazy(
  () =>
    import(/* webpackChunkName:"MeshUpload" */ "modules/toAvatar/AvatarUpload")
);
const TAViewer = SuspenseLazy(
  () =>
    import(
      /* webpackChunkName:"TMViewer" */ "modules/toAvatar/components/TMViewer"
    )
);

const PBUpload = SuspenseLazy(
  () =>
    import(
      /* webpackChunkName:"PBUpload" */ "modules/photoBooth/components/PBUpload"
    )
);
const PBViewer = SuspenseLazy(
  () =>
    import(
      /* webpackChunkName:"PBViewer" */ "modules/photoBooth/components/PBViewer"
    )
);

const ExViewer = SuspenseLazy(
  () =>
    import(
      /* webpackChunkName:"photoBooth" */ "modules/ResultViewer/RMeshViewer"
    )
);

const LocalViewer = SuspenseLazy(
  () => import("modules/ResultViewer/RMeshLocalViewer")
);

const Square = SuspenseLazy(
  () => import(/* webpackChunkName:"square" */ "modules/square/Square")
);

const NotFound = SuspenseLazy(
  () => import(/* webpackChunkName:"notFound" */ "common/components/NotFound")
);

const ToFace = SuspenseLazy(
  () => import(/* webpackChunkName:"toFace" */ "modules/toFace/ToFace")
);

const AppRoutes = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? Gallery : Landing} />
      <Route path="/login" element={Login} />
      <Route path="/signup" element={Signup} />
      <Route path="/findpw" element={FindPW} />
      <Route path="/landing" element={Landing} />
      <Route path="/payment" element={Payment} />
      <Route path="/profile" element={Profile} />
      <Route path="/gallery" element={Gallery} />
      <Route path="/create3d" element={Create3D} />
      <Route path="/photobooth" element={PhotoBooth} />
      <Route path="/photobooth-upload" element={PBUpload} />
      <Route path="/photobooth-generate" element={PBViewer} />
      <Route path="/photobooth-generate/:puid" element={PBViewer} />
      <Route path="/mesh" element={ToMesh} />
      <Route path="/mesh-upload" element={MeshUpload} />
      <Route path="/mesh-generate" element={TMViewer} />
      <Route path="/avatar" element={ToAvatar} />
      <Route path="/avatar-upload" element={AvatarUpload} />
      <Route path="/avatar-generate" element={TAViewer} />
      <Route path="/mingle" element={ToMingle} />
      <Route path="/mingle-upload" element={MingleUpload} />
      <Route path="/mingle-generate" element={TMGViewer} />
      <Route path="/square/:modelType/:uid" element={Square} />
      <Route path="/face" element={ToFace} />
      <Route path="/example_view/:oid" element={ExViewer} />
      <Route path="/local_viewer" element={LocalViewer} />
      <Route path="*" element={NotFound} />
    </Routes>
  );
};

export default AppRoutes;
