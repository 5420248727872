import React, { Suspense, lazy } from "react";

const SuspenseLazy = (props: any) => {
  return (
    <Suspense
      fallback={
        <div style={{ background: "black", width: "100vw", height: "100vh" }}>
          ...
        </div>
      }
    >
      {React.createElement(lazy(props))}
    </Suspense>
  );
};

export default SuspenseLazy;
