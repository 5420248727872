// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* For Webkit-based browsers like Chrome, Safari, and Edge */
::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  background: transparent; /* make the track transparent */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(184, 84, 59, 0.5); /* color of the scrollbar thumb */
  border-radius: 2px; /* roundness of the scrollbar thumb */
  border: 1px solid transparent; /* create padding around thumb */
  background-clip: padding-box; /* apply padding */
}

:root {
  --base-color: #18ff82;
  --base-color-90: rgba(24, 255, 130, 0.9);
  --base-color-70: rgba(24, 255, 130, 0.7);
  --base-color-50: rgba(24, 255, 130, 0.5);
  --base-color-30: rgba(24, 255, 130, 0.3);
  --base-color-10: rgba(24, 255, 130, 0.1);
}

html {
  scroll-behavior: smooth;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
  scrollbar-width: none;
}

body {
  font-family: "Space Grotesk", sans-serif;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,4DAA4D;AAC5D;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB,EAAE,+BAA+B;AAC1D;;AAEA;EACE,wCAAwC,EAAE,iCAAiC;EAC3E,kBAAkB,EAAE,qCAAqC;EACzD,6BAA6B,EAAE,gCAAgC;EAC/D,4BAA4B,EAAE,kBAAkB;AAClD;;AAEA;EACE,qBAAqB;EACrB,wCAAwC;EACxC,wCAAwC;EACxC,wCAAwC;EACxC,wCAAwC;EACxC,wCAAwC;AAC1C;;AAEA;EACE,uBAAuB;EACvB,+CAA+C;EAC/C,qBAAqB;AACvB;;AAEA;EACE,wCAAwC;EACxC,SAAS;EACT;;iBAEe;EACf,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;gBACc;AAChB","sourcesContent":["/* For Webkit-based browsers like Chrome, Safari, and Edge */\n::-webkit-scrollbar {\n  display: none;\n}\n\n::-webkit-scrollbar-track {\n  background: transparent; /* make the track transparent */\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: rgba(184, 84, 59, 0.5); /* color of the scrollbar thumb */\n  border-radius: 2px; /* roundness of the scrollbar thumb */\n  border: 1px solid transparent; /* create padding around thumb */\n  background-clip: padding-box; /* apply padding */\n}\n\n:root {\n  --base-color: #18ff82;\n  --base-color-90: rgba(24, 255, 130, 0.9);\n  --base-color-70: rgba(24, 255, 130, 0.7);\n  --base-color-50: rgba(24, 255, 130, 0.5);\n  --base-color-30: rgba(24, 255, 130, 0.3);\n  --base-color-10: rgba(24, 255, 130, 0.1);\n}\n\nhtml {\n  scroll-behavior: smooth;\n  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;\n  scrollbar-width: none;\n}\n\nbody {\n  font-family: \"Space Grotesk\", sans-serif;\n  margin: 0;\n  /* font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  /* font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
